import React from "react";
import "./company_widget1.css";
import wings from '../../assets/images/Wings.svg';
import img from '../../assets/images/Office.svg';
import location from '../../assets/images/Location.svg';
function CompanyWidget1(){
  return (
    <div className="company-widget1">
        <div className="cw1-left">
            <p className="cw1-maintext">Codesnag: Your Trusted Partner<span className="cw1-span-text"> in Cybersecurity Excellence</span></p>
        </div>
        <div className="cw1right">
        <div className="zoom-container">
          <img src={img} alt="office" className="cw1rightimg2" />
          <div className="cw1-black-overlay">
          <p className="cw1-text2">Codesnag</p>
          <div className="cw1-address cw1-add-display">
          <img alt="location" className="cw1-address-icon" src={location}/>
          <p className="cw1-text3">Head Branch at New Delhi</p>
          </div>
          </div>
        </div>
      </div>
        <div className="cw1-grid">
            <div className="cw1-moving-grid"></div>
            <img src={wings} alt="Wings Background" className="cw1-wings" />
        </div>
    </div>
  );
}

export default CompanyWidget1;
