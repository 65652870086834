import React from "react";
// import { useNavigate } from "react-router-dom"; 

import "./css/company_page.css";
import Navbar from "./Navbar";
import HomeFooter from "./home_components/HomeFooter";
import CompanyWidget1 from "./companypage_components/CompanyWidget1";
import CompanyWidget2 from "./companypage_components/CompanyWidget2";
import CompanyWidget3 from "./companypage_components/CompanyWidget3";
import CompanyWidget4 from "./companypage_components/CompanyWidget4";

const CompanyPage = () => {
  return (
   <>  
   <Navbar activeMenu={"Company"}/>
   <div className="company_page">
        <CompanyWidget1/>
        <CompanyWidget2/>
        <CompanyWidget3/>
        <CompanyWidget4/>
        <HomeFooter activeMenu={"Company"}/>
    </div>
   
    </>
  );
};


export default CompanyPage;

