import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/WhiteLogo.svg";
import blacklogo from "../assets/images/BlackLogo.svg";
import ScheduleDemoForm from "../components/ScheduleDemoForm";

import "./css/navbar.css";

import { Squash as Hamburger } from "hamburger-react";
import ReportIncidentForm from "./ReportIncidentForm";

function Navbar({ activeMenu }) {
  const navigate = useNavigate();
  const [showReportForm, setShowReportForm] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isMobile, mobile] = useState(false);
  const [showDemoForm, setShowDemoForm] = useState(false);
  const handleOpenDemoForm = (e) => {
    e.preventDefault();
    if (isOpen) {
      set();
    }
    // Show the popup
    setTimeout(() => {
      setShowDemoForm(true);
    }, 500);
  };
  const handleOpenForm = (e) => {
    e.preventDefault();
    if (isOpen) {
      set();
    }
    // Show the popup
    setTimeout(() => {
      setShowReportForm(true);
    }, 500);
  };

  const handleCloseForm = () => {
    setShowReportForm(false);
  };

  const handleCloseDemoForm = () => {
    setShowDemoForm(false);
  };
  const set = () => {
    setOpen(!isOpen);
    if (isMobile) {
      setTimeout(() => {
        mobile(false);
      }, 500);
    } else {
      mobile(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      const shouldShowOverlay = window.scrollY > 1;
      setShowOverlay(shouldShowOverlay);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleButtonClick = () => {
    if (isOpen) {
      set();
      setTimeout(() => {
        navigate("/");
      }, 500);
    } else {
      navigate("/");
    }
  };

  const companyButtonClick = () => {
    if (isOpen) {
      set();
      setTimeout(() => {
        navigate("/company");
      }, 500);
    } else {
      navigate("/company");
    }
  };
  const productButtonClick = () => {
    if (isOpen) {
      set();
      setTimeout(() => {
        navigate("/product");
      }, 500);
    } else {
      navigate("/product");
    }
  };
  const resourcesButtonClick = () => {
    if (isOpen) {
      set();
      setTimeout(() => {
        navigate("/resources");
      }, 500);
    } else {
      navigate("/resources");
    }
  };
  const redteamButtonClick = () => {
    if (isOpen) {
      set();
      setTimeout(() => {
        navigate("/redteam");
      }, 500);
    } else {
      navigate("/redteam");
    }
  };

  return (
    <>
      <div
        className={
          activeMenu === "Resources" || activeMenu === "Case Study"
            ? "whitenavbarContainer"
            : "navbarContainer"
        }
      >
        {showDemoForm && <ScheduleDemoForm onClose={handleCloseDemoForm} />}
        <img
          className="navImage"
          src={
            activeMenu === "Resources" || activeMenu === "Case Study"
              ? blacklogo
              : logo
          }
          alt=""
          onClick={handleButtonClick}
        />
        <div className="menuItems">
          <div
            className={`menuItem ${activeMenu === "Company" ? "active" : ""}`}
            onClick={companyButtonClick}
          >
            <p>Company</p>
          </div>
          <div
            className={`menuItem ${activeMenu === "Product" ? "active" : ""}`}
            onClick={productButtonClick}
          >
            <p>Product</p>
          </div>
          <div
            className={`menuItem ${activeMenu === "Resources" ? "active" : ""}`}
            onClick={resourcesButtonClick}
          >
            <p>Resources</p>
          </div>
          <div
            className={`menuItem ${activeMenu === "Red Team" ? "active2" : ""}`}
            onClick={redteamButtonClick}
          >
            <p>Red Team</p>
          </div>
        </div>
        <div
          className={activeMenu === "Red Team" ? "navButton2" : "navButton"}
          onClick={handleOpenDemoForm}
        >
          <p>Schedule a Demo</p>
        </div>
        <div onClick={handleOpenForm} className={activeMenu === "Red Team" ? "navButton2" : "navButton"}>
            <p>Report Incident</p>
          </div>
          {showReportForm && <ReportIncidentForm onClose={handleCloseForm} />}

          <div className={activeMenu === "Red Team" ? "menu-icon2" : (activeMenu === "Resources" ? "menu-icon-res" : "menu-icon")}>
          <Hamburger toggled={isOpen} size={25} toggle={set} />
        </div>
      </div>
      <div
        className={`${
          activeMenu === "Resources" || activeMenu === "Case Study"
            ? "overlayWhite"
            : "overlay"
        } ${showOverlay ? "active" : ""}`}
      />
      {isMobile ? (
        <div
          className={
            isOpen ? "mobileMenuBack active top-in" : "mobileMenuBack notactive"
          }
        ></div>
      ) : null}
      {isMobile ? (
        <div
          className={
            isOpen ? "mobileMenu active slide-in" : "mobileMenu notactive"
          }
        >
          <div
            className={`menuItem ${activeMenu === "Company" ? "active" : ""}`}
            onClick={companyButtonClick}
          >
            <p>Company</p>
            <div
              className={`circle-arrow ${
                activeMenu === "Company" ? "active" : ""
              }`}
            >
              &#8250;
            </div>
          </div>
          {/* // <div className='sub-menu'><p>About Us</p><div class="circle-arrow">&#8250;</div></div>
                // <div className='sub-menu'><p>Media</p><div class="circle-arrow">&#8250;</div></div> */}

          <div class="line"></div>

          <div
            className={`menuItem ${activeMenu === "Product" ? "active" : ""}`}
            onClick={productButtonClick}
          >
            <p>Product</p>
            <div
              className={`circle-arrow ${
                activeMenu === "Product" ? "active" : ""
              }`}
            >
              &#8250;
            </div>
          </div>

          <div class="line"></div>

          <div
            className={`menuItem ${activeMenu === "Resources" ? "active" : ""}`}
            onClick={resourcesButtonClick}
          >
            <p>Resources</p>
            <div
              className={`circle-arrow ${
                activeMenu === "Resources" ? "active" : ""
              }`}
            >
              &#8250;
            </div>
          </div>

          <div class="line"></div>

          <div
            className={`menuItem ${activeMenu === "Red Team" ? "active2" : ""}`}
            onClick={redteamButtonClick}
          >
            <p>Red Team</p>
            <div
              className={`circle-arrow ${
                activeMenu === "Red Team" ? "active2" : ""
              }`}
            >
              &#8250;
            </div>
          </div>

          <div
            className={
              activeMenu === "Red Team" ? "mobilenavButton2" : "mobilenavButton"
            }
            onClick={handleOpenDemoForm}
          >
            <p>Schedule a Demo</p>
          </div>
          <div onClick={handleOpenForm} className={activeMenu === "Red Team" ? "mobilenavButton2" : "mobilenavButton"}>
            <p>Report Incident</p>
          </div>
          {showReportForm && <ReportIncidentForm onClose={handleCloseForm} />}
          
         
        </div>
      ) : null}

      
    </>
  );
}
export default Navbar;
