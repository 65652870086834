import React from "react";
import "./company_widget2.css";

import img1 from '../../assets/images/CompanyS2Img1.svg';
import img2 from '../../assets/images/CompanyS2Img2.svg';
import img3 from '../../assets/images/CompanyS2Img3.svg';

function CompanyWidget2(){
  return (
    <div className="company-widget2">
        <p className="cw2-t1">Our Values</p>
        <div className="cw2-grid">
        <div className="cw2-card">
            <div className="cw2-whitecard">
                <p className="cw2-t2">Security Excellence</p>
                <p className="cw2-t3">Guardians of Cyber Fortresses</p>
                <img alt="img1" className="cw2-card-img1" src={img1}/>
            </div>
        </div>
        <div className="cw2-card">
            <div className="cw2-whitecard">
                <p className="cw2-t2">Customer Trust and Transparency</p>
                <p className="cw2-t3">Building Strong Partnerships Through Transparency</p>
                <img alt="img1" className="cw2-card-img2" src={img2}/>
            </div>
        </div>
        <div className="cw2-card">
            <div className="cw2-whitecard">
                <p className="cw2-t2">Innovation and Adaptability</p>
                <p className="cw2-t3">Pioneering Solutions for Tomorrow's Threats</p>
                <img alt="img1" className="cw2-card-img3" src={img3}/>
            </div>
        </div>
        </div>
    </div>
  );
}

export default CompanyWidget2;
