import React from "react";
import "./company_widget4.css";

import img1 from '../../assets/images/CompanyW4.svg';
import indeed from '../../assets/images/IndeedIcon.svg';

function CompanyWidget4(){
  const scanNavigate = () => {
    window.location.href = 'https://in.indeed.com/cmp/Codesnag/jobs';
  };
  return (
    <div className="company-widget4">
        <p className="cw2-t1">Job Openings</p>
        <div className="cw4-container">
        <div className="cw4-left">
          <p className="cw4-t2">Join Our Team</p>
          <p className="cw4-t3">Elevate Your Career in Cybersecurity Excellence</p>
          <div className="cw4-bh">
          <div className="cw4-button" onClick={scanNavigate}>
              <p className="cw4-t4"> Apply on Indeed 
             <img src={indeed} alt="indeed" className="cw4-indeed-icon"/> →</p>
          </div>
          </div>
        </div>
        <div className="cw4-right">
          <img src={img1} alt="job" className="cw4-img"/>
        </div>
        </div>
    </div>
  );
}

export default CompanyWidget4;
