import React, { useState, useEffect, useRef } from "react";
import "./css/form1.css";

const ScheduleDemoForm = ({ onClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const formRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", { name, email, phone, company });
    onClose();
  };

  return (
    <div className="form-popup" ref={formRef}>
      <div className="form1-head">
        <p className="form1-text1">Schedule a Demo</p>
        <div className="close-button" onClick={onClose}>
          <span>&times;</span>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="form1">
        <input
          placeholder="Name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <input
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <input
          placeholder="Phone Number"
          type="tel"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />

        <input
          placeholder="Company Name"
          type="text"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          required
        />

        <button type="submit" className="form1-buttom">Schedule a Demo</button>
      </form>
    </div>
  );
};

export default ScheduleDemoForm;
